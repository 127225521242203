<template>
    <div class="chart-container" v-if="!currentQuarterTargetExceeded">
		<v-overlay
			:opacity="0.15"
			:value="loader"
			absolute
		>
			<v-row>
				<v-col class="text-center">
					<v-progress-circular
						color="primary"
						indeterminate
						size="40"
						width="6"
					/>
				</v-col>
			</v-row>
		</v-overlay>
		<v-card elevation="6" class="mx-auto" :height="$vuetify.breakpoint.smAndDown ? '' : '495px'">
			<v-card-text>
				<div class="d-flex flex-row black--text text--darken-1 label" v-html="purify(label1)"></div>
				<div class="black--text darken-1">{{ label2 }}</div>
				<div class="error-container" v-if="loadingError">
					<v-alert dense outlined type="error" class="mt-3 mb-0">{{ errorMessage }}</v-alert>
				</div>
				<v-simple-table dense :class="'missing-sales' + (!$vuetify.breakpoint.smAndDown ? ' mt-3' : '')">
					<template v-slot:default>
						<tr>
							<th class="text-start width-35-pct table-header-text text-decoration-underline">{{ headerText1 }}</th>
							<th class="text-right table-header-text text-decoration-underline">{{ headerText2 }}</th>
						</tr>
						<tbody>
						<template>
							<tr v-for="requiredSale in requiredSales">
								<td class="text-start pl-0 text-no-wrap">
									{{ '$' + formatThisNumber(requiredSale['missingSalesValue'],'0,0') }}
								</td>
								<td class="text-right pr-0">{{ '' + requiredSale['margin'] + '%' }}</td>
							</tr>
						</template>
						</tbody>
					</template>
				</v-simple-table>
			</v-card-text>
		</v-card>
    </div>
</template>

<script>
import {numberFormat, purify} from "Helpers/helpers";
import {api} from 'Api';
export default {
	name: "CurrentMissingGrossMargin",
    props: ['conditions'],
	data(){
		return {
			currentQuarterMissingGrossMargin: 0,
			currentQuarterTargetExceeded: false,
			errorMessage: null,
			headerText1: '',
			headerText2: '',
			label1: '',
			label2: '',
			loader: true,
			loadingError: false,
			requiredSales: []
		}
	},
    methods: {
		formatThisNumber (value,format) {
			return numberFormat(value,format)
		},
		getValues() {
			return new Promise((resolve, reject) => {
				const url = '/sparkline/chart/gm-current-missing'
				if(url) {
					api
						.get(url, {
							params: {
								conditions: this.conditions ? this.conditions : []
							}
						})
						.then((response) => {
							const data = response.data.data
							resolve(data)
						})
						.catch((error) => {
							reject(error)
						})
				} else {
					reject('error')
				}
			})
		},
		purify
    },
	mounted() {
		this.getValues()
			.then((data) => {
				this.label1 = data.label1
				this.label2 = data.label2
				this.headerText1 = data.headerText[0]
				this.headerText2 = data.headerText[1]
				this.requiredSales = data.requiredSales
				this.currentQuarterTargetExceeded = data.currentQuarterTargetExceeded
				this.currentQuarterMissingGrossMargin = data.currentQuarterMissingGrossMargin
				this.loader = false
			})
			.catch((error) => {
				this.loader = false
				this.loadingError = true
				this.errorMessage = error
			})
	}
}
</script>

<style scoped>
.width-35-pct {
	width: 35% !important;
}
.missing-sales tbody td {
    /*font-size: 16px !important;*/
}
.chart-container {
	position: relative;
}
.label {
	font-weight: bold;
	font-size: 1.25em !important;
}
.table-header-text {
	font-weight: unset !important;
}
.v-data-table--dense > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table--dense > .v-data-table__wrapper > table > thead > tr > td, .v-data-table--dense > .v-data-table__wrapper > table > tfoot > tr > td {
	height: 34px !important;
}
</style>